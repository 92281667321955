.containerAboutUs2 {
  padding: 88px 124px;
  background-color: #232323;
  @media only screen and (max-width: 991px) and (min-width: 768px) {
    padding: 88px 69px;
  }
  @media only screen and (min-width: 480px) and (max-width: 767px) {
      padding: 89px 34px;   
  }
  @media only screen and (max-width: 479px) {
      padding: 1rem 1rem;
  }
}

.grid-container33 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    grid-gap: 0px;
    @media only screen and (min-width:480px) and (max-width: 767px) {
      grid-template-columns: repeat(1,1fr);
    }
    @media only screen and (max-width: 479px) {
      grid-template-columns: repeat(1,1fr);
    }
  }

  .imageContact {
    width: fit-content;
    width: auto !important;
    height: 5vw;
    color: white;
    @media only screen and (max-width: 767px) and (min-width: 480px) {
      height: 110px;
    }
    @media only screen and (max-width: 479px) {
      height: 110px;
    }
  }

  .teks-Telepon {
    font-size: 1vw;
    @media only screen and (max-width: 1400px) and (min-width: 992px) {
      font-size: 9px;
    }
    @media only screen and (max-width: 991px) and (min-width: 768px) {
      font-size: 9px;
    }
    @media only screen and (max-width: 767px) and (min-width: 480px) {
      font-size: 18px;
    }
    @media only screen and (max-width: 479px) {
      font-size: 15px;
    }
  }
  
  .grid-item12 {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #1f1f1f;
    height: 400px;
    @media only screen and (max-width: 1400px) and (min-width: 992px) {
      height: 350px;
    }
    @media only screen and (max-width: 991px) and (min-width: 768px) {
      height: 300px;
    }
    @media only screen and (max-width: 479px) {
      height: 450px;
    }
  }

  .dataMap {
    width: 100%;
    height: 600px;
    @media only screen and (max-width: 991px) and (min-width: 768px) {
      height: 400px;
    }
    @media only screen and (max-width: 479px) {
      height: 300px;
    }
  }

  .grid-item13 {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #1f1f1f;
    height: 600px;
    @media only screen and (max-width: 1400px) and (min-width: 992px) {
      height: 350px;
    }
    @media only screen and (max-width: 991px) and (min-width: 768px) {
      height: 300px;
    }
    @media only screen and (max-width: 479px) {
      height: 450px;
    }
  }

  .containerContact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h2 {
        font-family: 'Poppins-Bold';
        font-size: 1.8vw;
        @media only screen and (max-width: 767px) and (min-width: 480px) {
          font-size: 22px;
        }
        @media only screen and (max-width: 479px) {
          font-size: 22px;
        }
    }
  }

  .gambar {
    width: 300px !important;
  }

  .hrPerusahaan {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ffffff;
    width: 70px;
    margin-bottom: 1rem;
  }

  .inputText {
    font-family: 'Poppins-Regular';
    font-size: 16px;
    color: white;
  }

  .flexAha {
    flex: 1;
    @media only screen and (max-width: 767px) and (min-width: 480px)  {
      margin-bottom: 2rem;
    }
    @media only screen and (max-width: 479px) {
      margin-bottom: 1rem;
    }
  }

  .oleInput {
    background-color: transparent;
    border: 1px solid white;
    color: white;
    height: 50px;
    width: 80%;
    margin-bottom: 2rem;
    @media only screen and (max-width: 767px) and (min-width: 480px)  {
      width: 100%;
    }
    @media only screen and (max-width: 479px) {
      width: 100%;
    }
  }

  .rowAha {
    display: flex;
    flex-direction: row;
    @media only screen and (max-width: 767px) and (min-width: 480px)  {
      flex-direction: column;
    }
    @media only screen and (max-width: 479px) {
      flex-direction: column;
    }
  }

  .oleInput2 {
    background-color: transparent;
    border: 1px solid white;
    color: white;
    height: 200px;
    width: 80%;
    @media only screen and (max-width: 767px) and (min-width: 480px)  {
      width: 100%;
    }
    @media only screen and (max-width: 479px) {
      width: 100%;
    }
  }