.whiteColor {
    font-family: 'Poppins-Regular';
    color: white;
}

.card-container2 {
    display: grid;
    justify-content: flex-start;
    grid-template-columns: repeat(3, 1fr);
    @media only screen and (max-width: 991px) and (min-width: 768px) {
      grid-template-columns: repeat(2, 1fr); 
      gap: 8px; 
    }
    @media only screen and (min-width: 480px) and (max-width: 767px) {
        grid-template-columns: repeat(1, 1fr); 
      gap: 7px;
    }
    @media only screen and (max-width: 479px) {
      grid-template-columns: repeat(1, 1fr); 
    }
    .card {
        justify-content: flex-start;
    }
  }