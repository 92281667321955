.sidebarxclose {
    display: none;
    z-index: 9999;
    right: 0px;
    width: 200px;
    height: 100vh;
    background-color: #f0f0f0;
    position: fixed;
    top: 0px;
}

.sidebarxopen {
    display: block;
    z-index: 9999;
    right: 0px;
    width: 200px;
    height: 100vh;
    background-color: #f0f0f0;
    position: fixed;
    top: 0px;
}

.headerx {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f0f0f0;
  padding: 10px;
}

.sidebar-togglex {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
  margin-top: 3rem;
  margin-right: 26px;
}

.logox {
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

.menu-iconx {
  font-size: 20px;
  color: #333;
  cursor: pointer;
}

.menux {
  display: none;
}

.menu-listx {
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-top: 5rem;
}

.menu-list-itemx {
  margin-bottom: 10px;
}

.menu-list-linkx {
  text-decoration: none;
  color: #333;
  font-size: 16px;
}

/* Media Query for Mobile */
@media (max-width: 768px) {
  .headerx {
    padding: 10px;
  }

  .menux {
    display: block;
  }

  .menu-list-itemx {
    text-align: center;
  }
}
/* Add more styles as needed */
.divLanguageMobile {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 7px;
  border-radius: 7px;
}

.languageTextMobile {
	text-decoration: none;
    color: #333;
    font-size: 16px;
    margin-left: 10px;
}