.table {
    display: flex;
    flex-direction: column;
  }
  
  .row {
    display: flex;
    justify-content: center;
  }
  
  .cell {
    padding: 10px;
    border: 1px solid #ccc;
  }

  .selected {
    border: 1px solid greenyellow;
  }
  
  .cell-1 {
    padding-top: 18px;
    height: 65px;
    color: white;
    width: 79px;
  }
  
  .cell-2 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: white;
    max-width: 700px;
    @media only screen and (max-width: 1024px) and (min-width: 992px) {
      width: 688px;
    }
    @media only screen and (max-width: 991px) and (min-width: 768px) {
      width: 465px;
    }
    @media only screen and (min-width: 480px) and (max-width: 767px) {
      width: 357px;
    }
    @media only screen and (max-width: 479px) {
      width: 250px;
    }
  }

.containerCarrer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.jobTitle {
    display: flex;
    justify-content: space-between;
    width: 780px;
    margin-bottom: 2rem;
    @media only screen and (max-width: 991px) and (min-width: 768px) {
      width: 508px;
    }
    @media only screen and (min-width: 480px) and (max-width: 767px) {
      width: 412px;
    }
    @media only screen and (max-width: 479px) {
      width: 100%;
    }
}

.ColumnJob {
        display: flex;
        flex-direction: column;
}

.titleForJob {
    font-family: 'Poppins-Bold';
    font-size: 20px;
    color: white;
}

.subTitleForJob {
    font-family: 'Poppins-Regular';
    font-size: 16px;
    color: white;
}

.applyNow {
    font-family: 'Poppins-Regular';
    font-size: 14px;
    border-radius: 4px;
    background-color: #01C079;
    color: white;
    padding: 15px;
}

.descJob {
    font-family: 'Poppins-Regular';
    font-size: 16px;
    color: white;
    @media only screen and (max-width: 991px) and (min-width: 768px) {
      width: 508px;
    }
    @media only screen and (min-width: 480px) and (max-width: 767px) {
      width: 412px;
    }
}

.ole {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 780px;
    margin-bottom: 2rem;
    @media only screen and (max-width: 991px) and (min-width: 768px) {
      width: 508px;
    }
    @media only screen and (min-width: 480px) and (max-width: 767px) {
      width: 100%;
    }
    @media only screen and (max-width: 479px) {
      width: 298px;
    }
}

.redButtonMantap {
    font-family: 'Poppins-Bold';
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    padding: 15px;
    color: white;
    border-radius: 15px;
    background-color: #890505;
}

.ulala {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    @media only screen and (min-width: 480px) and (max-width: 767px) {
      width: 412px;
    }
}