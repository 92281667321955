.imageBannerClient {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.wawaPeople {
    width: 100px;
    height: auto;
    margin-right: 2rem;
}

.containerPeople {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-top: 6rem;
}

.imageRealClient{
    width: 100%;
}

.hrLineClient {
    border: 3px solid white;
    width: 262px;
}

.text-CLient {
    font-family: 'Poppins-Bold';
    font-size: 40px;
    color: white;
}

.desc-CLient {
    font-family: 'Poppins-Regular';
    font-size: 20px;
    color: white;
    margin-bottom: 0;
    justify-content: flex-start;
    margin-top: 4px;
}

.colData {
    display: flex;
    justify-content: center;
    flex-direction: column;
}