.containerAboutUs {
  padding: 89px 164px;
  background-color: #232323;
  @media only screen and (max-width: 991px) and (min-width: 768px) {
    padding: 89px 102px;
  }
  @media only screen and (min-width: 480px) and (max-width: 767px) {
     padding: 89px 34px;
  }
  @media only screen and (max-width: 479px) {
    padding: 1rem 1rem;   
	}
}

.grid-item212 {
  display: flex;
  justify-content: center;
  display: flex !important;
    justify-content: center;
    align-items: center;
}

.grid-item212 img {
  width: 160px;
  height: 160px;
  object-fit: contain;
}

.containerOurClient {
  padding: 89px 164px;
  background-color: #FFFFFF;
  @media only screen and (min-width: 480px) and (max-width: 767px) {
    padding: 89px 40px;  
  }
  @media only screen and (max-width: 479px) {
    padding: 1rem 1rem;   
	}
}

.imagesContent {
    position: relative;
    width: 38vw;
    height: 100%;
    margin-bottom: 2rem;
    border-radius: 2vw;
    @media only screen and (max-width: 991px) and (min-width: 768px) {
     width: 90vw; 
     border-radius: 39px;
    }
    @media only screen and (min-width: 480px) and (max-width: 767px) {
     width: 90vw;
     border-radius: 39px;
    }
    @media only screen and (max-width: 479px) {
    width: 100%; 
    border-radius: 39px;
    }
}

.iconImageRound {
  @media only screen and (max-width: 991px) and (min-width: 768px) {
    width: 20px;
  }
  @media only screen and (min-width: 480px) and (max-width: 767px) {
    width: 20px;  
  }
}

.marginTextImage{
    margin-left: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media only screen and (max-width: 991px) and (min-width: 768px) {
      margin-left: 0;
    }
    @media only screen and (min-width: 480px) and (max-width: 767px) {
      margin-left: 1rem;
    }
    @media only screen and (max-width: 479px) {
      margin-left: 1rem; 
    }
}

.hrLineaboutUs {
    border: 2px solid white;
    width: 58px;
    margin-bottom: 3rem;
    @media only screen and (max-width: 991px) and (min-width: 768px) {
      margin-bottom: 1rem;
    }
    @media only screen and (min-width: 480px) and (max-width: 767px) {
      margin-bottom: 1rem;
    }
    @media only screen and (max-width: 479px) {
      margin-bottom: 1rem; 
    }
}

.buttonSeeMore {
    font-family: 'Poppins-Bold';
    display: flex;
    justify-content: center;
    align-items: center;
    height: 44px;
    width: 172px;
    color: white;
    background-color: #890505;
    border-radius: 10px;

}

.hrLayananKami {
    display: flex;
    justify-content: center;
    border: 2px solid white;
    width: 58px;
    margin-bottom: 3rem;
}

.hrKlienKami{
    display: flex;
    justify-content: center;
    border: 2px solid black;
    width: 58px;
    margin-bottom: 3rem;
}

.containerTitle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.aboutUs {
    font-family: 'Poppins-Bold';
    font-size: 2.2vw;
    margin-bottom: 1rem;
    color: white;
    @media only screen and (min-width: 601px) and (max-width: 1024px)  {
      font-size: 20px;
    }
    @media only screen and (max-width: 600px)  {
      font-size: 20px;
    }
}

.ourClient {
    font-family: 'Poppins-Bold';
    margin-bottom: 1rem;
    color: black;
}

.containerLayananKami {
    margin-top: 2rem;
}

.textAboutUs {
    margin-left: 1rem;
    font-family: 'Poppins-Regular';
    color: white;
    font-size: 1vw;
    @media only screen and (min-width: 768px) and (max-width: 992px)  {
      font-size: 16px;
    }
    @media only screen and (min-width: 480px) and (max-width: 767px) {
      font-size: 16px;
    }
    @media only screen and (max-width: 479px) {
      font-size: 14px;
      margin-left: 0; 
    }
}

.card-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 70px;
    @media only screen and (max-width: 991px) and (min-width: 768px) {
      grid-template-columns: repeat(2, 1fr); 
      gap: 19px; 
    }
    @media only screen and (min-width: 480px) and (max-width: 767px) {
      grid-template-columns: repeat(2, 1fr); 
      gap: 19px; 
    }
    @media only screen and (max-width: 479px) {
      grid-template-columns: repeat(1, 1fr); 
    }
  }
  
  .card {
    padding: 1rem;
    display: flex;
    background-color: transparent;
    border: 0px solid #ddd;
    border-radius: 19px;
    overflow: hidden;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .card2 {
    padding: 1rem;
    display: flex;
    background-color: white;
    border: 0px solid #ddd;
    border-radius: 19px;
    overflow: hidden;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    @media only screen and (min-width: 480px) and (max-width: 767px) {
      padding: 5px; 
    }
    @media only screen and (max-width: 479px) {
      padding: 5px; 
      width: 80%;
      margin: auto;
      
    }
   
   
  }

  .redRoundImage {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    border-radius: 100%;
    background: #890505;
    @media only screen and (max-width: 991px) and (min-width: 768px) {
      width: 40px;
      height: 40px; 
    }
    @media only screen and (min-width: 480px) and (max-width: 767px) {
      width: 40px;
      height: 40px;
    }
  }
  
  .card-image {
    width: 100%;
    height: auto;
    border-radius: 13px;
  }
  
  .card-content {
    padding: 10px;
  }

  .card-text {
    display: flex;
    font-family: "Poppins-Regular";
    font-size: 20px;
    color: #FFFFFF;
    margin-left: 18px;
    justify-content: center;
    align-items: center;
  }

  .card-text2 {
    display: flex;
    font-family: "Poppins-Regular";
    font-size: 20px;
    color: #000000;
    margin-left: 18px;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    @media only screen and (max-width: 991px) and (min-width: 768px) {
      font-size: 14px; 
    }
    @media only screen and (min-width: 480px) and (max-width: 767px) {
      font-size: 12px;
      margin-left: 6px; 
    }
  }

  .card-content {
    display: flex;
    flex-direction: row;
  }

  .ole-content {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
  }

  .image-grid {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-gap: 7px;
    @media only screen and (min-width: 480px) and (max-width: 767px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media only screen and (max-width: 479px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  
  .grid-item {
    width: 100%;
    height: 100%;
    @media only screen and (min-width: 480px) and (max-width: 767px) {
      display: flex;
      justify-content: center;
    }
    @media only screen and (min-width: 480px) and (max-width: 767px) {
      display: flex;
      justify-content: center; 
    }
    @media only screen and (max-width: 479px) {
      display: flex;
      justify-content: center; 
    }
  }
  
  .grid-item img {
    width: 160px;
    height: 160px;
    object-fit: contain;
  }

  .imageLogo {
    width: 200px !important;
    height: 00px !important;
  }

@media only screen and (min-width: 1280px) and (max-width: 1599px) {
  .image-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .grid-item {
    display: flex;
    justify-content: center;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1279px) {
  .image-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .redRoundImage {
    width: 50px;
    height: 50px;
  }

  .iconImageRound {
    width: 28px;
  }

  .paragraphInformasi {
    width: 178px;
  }

  .quickLink {
    font-size: 20px;

    .nav-item {
      font-size: 14px;
    }
  }

  .card-container {
    gap: 21px;
  }

  .grid-item {
    display: flex;
    justify-content: center;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .image-grid {
    grid-template-columns: repeat(2, 1fr);

    .grid-item {
      display: flex;
      justify-content: center;
    }
  }  

  .content {
    padding: 34px;
  }                                                                                                                                                                                                                                    
}

.hrLinebetweenSection {
  border: none;
  height: 2px;
  background: linear-gradient(to right, transparent, #4b4b4b, transparent);
  margin: 2rem 0;
}

.video-container {
  position: relative;
}

.floating-buttons {
  position: absolute;
  bottom: 20px;
  right: 20px;
  display: flex;
  flex-direction: row;
  z-index: 1; /* Ensure the buttons appear above the video */
}

.floating-buttons button {
  margin-left: 10px;
  background-color: transparent;
  border: none;
  color: #ffffff;
  font-size: 20px;
  cursor: pointer;
}

.floating-buttons button:hover {
  color: #f0f0f0;
}


.hrLinebetweenSection2 {
  border: none;
  height: 2px;
  background: linear-gradient(to right, transparent, #4b4b4b, transparent);
  margin: 4rem 0;
}

.playButton {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.wiws {
  border-radius: 15px;
  display: flex;
  width: 100%;
}

.playBaton {
  width: 50px !important;
  height: 50px;
}

