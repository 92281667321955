.slick-arrow{
	position: absolute;
	top: 44%;
	height: 60px;
	width: 60px;
	text-align: center;
	line-height: 60px;
	border: 0;
	border-radius: 50%;
	background-color: transparent;
	color: white;
	z-index: 2;
	font-size: 20px;
	cursor: pointer;
	transform: translateY(-50%);
	&.prev{
		left: 80px;
	}
	&.next{
		right: 80px;
	}
}

.openNav {
	display: block;
}

.closeNav {
	display: none;
}

.navbarMobileBlack {
	z-index: 99999999;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    background-color: transparent;
    border-radius: 8px;
    border: 2px solid black;
	@media only screen and (min-width: 768px) {
		display: none;
	}
	@media only screen and (max-width: 767px) {
		display: flex;
	}
}

.navbarMobileWhite {
	z-index: 99999999;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    background-color: transparent;
    border-radius: 8px;
    border: 2px solid white;
	@media only screen and (min-width: 768px) {
		display: none;
	}
	@media only screen and (max-width: 767px) {
		display: flex;
	}
}

.bannerHome {
	width: 100vw;
	height: 100vh;
}

.imagesBanner {
	object-fit: contain;
	width: 100%;
	height: 100%;
	border-radius: 0;
	background-color: #232323;
}
.imagesBanner2 {
	object-fit: contain;
	width: 100%;
	height: 100%;
	border-radius: 14px;
	background-color: #232323;
}
.layer-filter {
	position: relative;
  }
  
.layer-filter::after {
content: '';
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: #232323;
opacity: 0.5;
}

.header {
	position: absolute;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 50px 75px;
    background-color: transparent;
    top: -7px;
    z-index: 9999;

	@media only screen and (max-width: 991px) and (min-width: 768px) {
		padding: 20px;
	}
	@media only screen and (max-width: 768px) {
		padding: 20px;
	}
}

.text-overlay {
	z-index: 999;
	position: absolute;
	top: 37vw;
    left: 27.5%;
	transform: translate(-50%, -50%);
	text-align: center;
	color: #fff;
	padding: 20px;

	@media only screen and (max-width: 1366px) {
		display: block;
		top: 37vw;
    	left: 27.5%;
	}

	@media only screen and (min-width: 991px) and (max-width: 1365px) {
		display: block;
		top: 37vw;
    	left: 27.5%;
	}

	@media only screen and (max-width: 990px) {
		display: none;
	}
}

.navbar {
	background-color: transparent;
	padding: 10px;
	@media only screen and (min-width: 480px) and (max-width: 767px) {
		display: none;
    }
	@media only screen and (max-width: 479px) {
		display: none;
	}
  }
  
  .nav-menu {
	list-style-type: none;
	margin: 0;
	padding: 0;
	display: flex;
  }
  
  .nav-item {
	margin-right: 20px;
	display: flex;
	@media only screen and (min-width: 480px) and (max-width: 767px) {
		margin-right: 10px;
    }
  }
  
  .nav-item:last-child {
	margin-right: 0;
  }
  
  .nav-item a {
	font-family: 'Poppins-Regular';
	font-weight: 800;
	font-size: 20px;
	text-decoration: none;
	color: white;
	padding: 8px;

	@media only screen and (min-width: 991px) and (max-width: 1366px) {
		font-size: 15px;
	}
	@media only screen and (max-width: 991px) and (min-width: 768px) {
		font-size: 13px;
    }

	@media only screen and (min-width: 480px) and (max-width: 767px) {
		font-size: 12px;
    }
  }
  
  .nav-item a:hover {
	background-color: #890505;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 10px;
	padding: 4px 8px;
  }

  .logoImage {
	@media only screen and (min-width: 480px) and (max-width: 767px) {
		width: 85px;
    }
	@media only screen and (max-width: 479px) {
		width: 80px;
	}
  }

.nav-menu {
	display: flex;
	flex-direction: row;
}

.textBanner {
	font-family: 'Poppins-Bold';
	font-size: 2vw;
	color: white;
	text-align: left;
	@media only screen and (min-width: 768px) and (max-width: 991px) {
		font-size: 22px;
	}
	@media only screen and (min-width: 480px) and (max-width: 767px) {
		font-size: 13px;
    }
}

.logo {
	@media only screen and (min-width: 991px) and (max-width: 1366px) {
		width: 98px;
	}
	@media only screen and (max-width: 991px) and (min-width: 768px) {
		width: 89px;
    }
	@media only screen and (min-width: 480px) and (max-width: 767px) {
		width: 60px;
    }
}

.divLanguage {
    border: 1px solid white;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 7px;
    border-radius: 7px;
}

.languageText{ 
	font-family: "Poppins-Regular";
    font-weight: 800;
    font-size: 20px;
    text-decoration: none;
    color: white;
	margin-left: 1rem;

	@media only screen and (min-width: 991px) and (max-width: 1366px) {
		font-size: 15px;
	}
	@media only screen and (max-width: 991px) and (min-width: 768px) {
		font-size: 13px;
    }

	@media only screen and (min-width: 480px) and (max-width: 767px) {
		font-size: 12px;
    }
}
