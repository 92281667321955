.footer {
  position: relative;
  background-image: url("../../../public/assets/images/footerImage.webp");
  background-size: cover;
  background-position: center;
  height: 403px;
  /* Additional styles for the footer */
}

.later-filter {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity as needed */
}

.footer-content {
  color: white;
}

// .footer-content:first-child {
//   .navbars {
//     @media only screen and (min-width: 480px) and (max-width: 767px) {
//       display: none;
//     }
//     @media only screen and (max-width: 479px) {
//       display: none; 
//     }
//   }
// }

.content {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  height: 100%;
  padding: 100px;

  @media only screen and (min-width: 991px) and (max-width: 1366px) {
    padding: 21px;
  }
  @media only screen and (max-width: 991px) and (min-width: 768px) {
    padding: 20px;
  }
  @media only screen and (min-width: 480px) and (max-width: 767px) {
    padding: 15px;
  }
  @media only screen and (max-width: 479px) {
    padding: 0px;   
  }
  /* Additional styles for the content */
}

.wawa {
  display: flex;
  flex-direction: row;
}

.wawa p {
  @media only screen and (max-width: 991px) and (min-width: 768px) {
    font-size: 14px;
  }
  @media only screen and (min-width: 480px) and (max-width: 767px) {
    font-size: 13px;
  }
  @media only screen and (max-width: 479px) {
    font-size: 10px;   
	}
}

.paragraphInformasi {
  width: 269px;
  @media only screen and (max-width: 991px) and (min-width: 768px) {
    width: 170px;
    font-size: 13px;
  }
  @media only screen and (min-width: 480px) and (max-width: 767px) {
    width: 102px;
    font-size: 13px;
  }
  @media only screen and (max-width: 479px) {
    width: 100px;
    font-size: 10px;   
  }
}

.quickLink {
  font-family: "Poppins-Bold";
  font-size: 20px;
  margin-bottom: 5px;

  @media only screen and (max-width: 991px) and (min-width: 768px) {
    font-size: 16px;
  }
  @media only screen and (min-width: 480px) and (max-width: 767px) {
    font-size: 14px;
  }
  @media only screen and  (max-width: 479px) {
      
  }
}

.footer-right {
  display: flex;
  flex-direction: row;
  // flex: 1;
  // margin-left: 500px;
}

.footer .footer-right{
  @media only screen and (min-width: 480px) and (max-width: 700) {
    flex: 1 !important; 
    justify-content: space-evenly; 
  }
}


.nav-menu1 {
  display: flex;
  flex-direction: column;
  @media only screen and (min-width: 480px) and (max-width: 767px) {
    padding-left: 1rem;   
  }
}

.nav-menu1 li a:hover {
  background-color: #890505;
  border-radius: 4px;
  padding: 1px 8px;
}

.nav-items {
  margin-right: 20px;
  margin-left: 15px;
}

.nav-items:last-child {
  margin-right: 0;
}

.nav-items a {
  text-decoration: none;
  color: white;
  padding: 8px;

}

.nav-items a {
  font-family: "Poppins-Regular";
  font-size: 14px;

  @media only screen and (max-width: 991px) and (min-width: 768px) {
    font-size: 12px;
  }

  @media only screen and (min-width: 480px) and (max-width: 767px) {
    font-size: 12px;
  }
}

.imageLogoFooter {
  height: 160px;
  width: 160px;

  @media only screen and (max-width: 991px) and (min-width: 768px) {
    width: 120px;
    height: 120px;
  }

  @media only screen and (min-width: 480px) and (max-width: 767px) {
    width: 100px;
    height: 100px;
  }
  @media only screen and (max-width: 479px) {
    width: 40px;
    height: 40px;   
	}
}

.footerBrown {
  display: flex;
  font-family: "Poppins-Regular";
  font-size: 18px;
  justify-content: center;
  align-items: center;
  height: 60px;
  background-color: #615f5f;
  color: white;
  @media only screen and (min-width: 480px) and (max-width: 767px) {
    font-size: 13px;
  }

  @media only screen and (max-width: 479px) {
    font-size: 11px;   
	}
}

.footer-left {
  @media only screen and (max-width: 600px) {
    display: none;   
	}
}
