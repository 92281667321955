.imagesContentAboutUs {
    height: auto;
    width: 108%;
    border-radius: 13px;
}

.containerAboutUs2 {
    padding: 88px 124px;
    background-color: #232323;
    @media only screen and (min-width: 480px) and (max-width: 767px) {
        padding: 89px 34px;   
    }
    @media only screen and (max-width: 479px) {
        padding: 1rem 1rem;
    }
}

.marginTextImage2{
    margin-left: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media only screen and (min-width: 992px) and (max-width: 1366px) {
        margin-left: 0rem;
    }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
        margin-left: 0rem;
    }
    @media only screen and (min-width: 480px) and (max-width: 767px) {
        margin-left: 0rem;   
    }
    @media only screen and (max-width: 479px) {
        margin-left: 0rem;   
    }
}

.iconRoundImage {
    @media only screen and (min-width: 992px) and (max-width: 1366px) {
        width: 25px;
    }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
        width: 25px;
    }
    @media only screen and (min-width: 480px) and (max-width: 767px) {
        width: 25px;   
    }
}

.iconRoundImage2 {
    @media only screen and (min-width: 1280px) {
        width: 44px;
    }
    @media only screen and (min-width: 992px) and (max-width: 1279px) {
        width: 25px;
    }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
        width: 25px;
    }
    @media only screen and (min-width: 480px) and (max-width: 767px) {
        width: 25px;   
    }
}

.asyik {
    @media only screen and (min-width: 768px) and (max-width: 991px) {
        flex-direction: column;
    }
    @media only screen and (min-width: 480px) and (max-width: 767px) {
        flex-direction: column;
    }
}

.buttonSeeMore1 {
    font-family: 'Poppins-Bold';
    display: flex;
    justify-content: center;
    align-items: center;
    height: 44px;
    width: 300px;
    color: white;
    background-color: #890505;
    border-radius: 10px;
    margin-top: 44px;
}

.wawo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 3rem;
}

.content-grid2 {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Change the number of columns as needed */
    grid-gap: 20px;
    @media only screen and (min-width: 768px) and (max-width: 991px) {
        grid-template-columns: repeat((2, 1fr));
        grid-gap: 50px;
    }
    @media only screen and (min-width: 480px) and (max-width: 767px) {
        grid-template-columns: repeat(1, 1fr);   
    }
    @media only screen and (max-width: 479px) {
        grid-template-columns: repeat(1, 1fr);   
    }
}

    .content-grid3 {
        display: grid;
        grid-template-columns: repeat(2, 1fr); /* Change the number of columns as needed */
        grid-gap: 100px;
        @media only screen and (min-width: 480px) and (max-width: 767px) {
            grid-template-columns: repeat(1, 1fr);
            grid-gap: 5px;
        }
        @media only screen and (max-width: 479px) {
            grid-template-columns: repeat(1, 1fr);
            grid-gap: 5px;   
        }
    }

.content-item {
    display: flex;
    flex-direction: column;
}

.bold-text {
    font-family: 'Poppins-Bold';
    color: white;
    display: flex;
    margin-top: 15px;
}

.descriptionss{
    font-family: 'Poppins-Regular';
    color: white;
    margin-top: 6px;
    @media only screen and (min-width: 992px) and (max-width: 1366px) {
        font-size: 13px;
    }
}

.imageSelectButton {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    border: 2px solid white;
    font-family: 'Poppins-Bold';
    font-size: 35px;
    border-radius: 4px;
    background-color: transparent;
    color: white;
    font-size: 16px;
    font-weight: bold;
    text-decoration: none;
    transition: background-color 0.3s, color 0.3s;
    width: 413px;
    @media only screen and (max-width: 479px) {
        width: 100%;   
    }
  }
  
  .imageSelectButton:hover {
    background-color: #fff;
    color: #890505;
  }